import React from 'react';
import lemons from "../../assets/img/lemons.png"
import lemon_shop from "../../assets/img/lemon_shop.svg"

export default function Intro(props) {
    return (
        <>
            <div className="intro">
                <img className="lemons" src={lemons} alt="Lemons" />
                <div className="cta">
                    <div className="cta_1">
                        WHEN LIFE GIVES YOU LEMONS,<br />SAY YEAH SURE!
                    </div>
                    <div className="cta_2">
                        FRESHLY SQUEEZED STILL LEMONADE IN A BOTTLE
                    </div>
                </div>
            </div>
            <div className="shop_lemon">
                <img className="lemon_shop" src={lemon_shop} alt="Lemon shop button" />
            </div>
        </>
    );
}