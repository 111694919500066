import React, { useState } from 'react';
import single from './single';
import bundle from './bundle';

export default function Products(props) {
    const [group, setGroup] = useState("flavours")

    return (
        <div className="products">
            <div className="text">
                <div className="yellow">
                    UNBEATABLE FRESHNESS
                </div>
                <div className="black">
                    NATURALLY SWEETENED
                </div>
                <div className="yellow">
                    MADE TO ORDER
                </div>
            </div>

            <div className="group_links">
                <div onClick={() => setGroup("flavours")} className={group === "flavours" ? "link active" : "link"}>
                    FLAVOURS
                </div>
                <div onClick={() => setGroup("bundles")} className={group === "bundles" ? "link active" : "link"}>
                    BUNDLES
                </div>
            </div>
            <div className="group_wrap">
                <div className={group === "flavours" ? "group active" : "group"} id="flavours">

                    {Object.entries(single).map((e, n) => {
                        return <div key={"single_" + n} className="flavour">
                            <div className="image">
                                <img className="flavour_item" src={e[1].background_img} alt={e[1].id} id={e[1].id} />
                                <img className="flavour_image" src={e[1].img} alt={e[1].title + " lemonade"} />
                            </div>
                            <div className="title">{e[1].title}</div>
                            <div className="description">{e[1].description}</div>
                        </div>
                    })}

                </div>
                <div className={group === "bundles" ? "group active" : "group"} id="bundles">

                    {Object.entries(bundle).map((e, n) => {
                        return <div key={"bundle_" + n} className="flavour">
                            <div className="image bundle">
                                <img className="flavour_image_bundle left" src={e[1].img_1} alt={e[1].alt_1} />
                                <img className="flavour_image" src={e[1].img_2} alt={e[1].alt_2} />
                                <img className="flavour_image_bundle right" src={e[1].img_3} alt={e[1].alt_3} />
                            </div>
                            <div className="title">{e[1].title}</div>
                            <div className="description">{e[1].description}</div>
                        </div>
                    })}

                </div>
            </div>
        </div>
    );
}