import honey from "../../assets/img/honey-bottle-svg.svg"
import original from "../../assets/img/original-bottle-svg.svg"
import mojito from "../../assets/img/mojito-bottle-svg.svg"

import leaf from "../../assets/img/leaf.svg"
import lemon from "../../assets/img/lemon.svg"
import honeycomb from "../../assets/img/honeycomb.svg"

const single = {
    honey: {
        id: "honeycomb",
        background_img: honeycomb,
        img: honey,
        title: "HONEY",
        description: "FRESH LEMONADE WITH HONEY"
    },
    original: {
        id: "lemon",
        background_img: lemon,
        img: original,
        title: "ORIGINAL",
        description: "FRESH LEMONADE"
    },
    mojito: {
        id: "leaf",
        background_img: leaf,
        img: mojito,
        title: "VIRGIN MOJITO",
        description: "FRESH LEMONADE WITH MINT"
    }
}

export default single