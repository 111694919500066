import React from 'react';
import logo from "../../assets/img/logo.svg"
import basket from "../../assets/img/basket.svg"
import account from "../../assets/img/account.svg"
import burger from "../../assets/img/burger.svg"

export default function Header(props) {
    return (
        <div className="header">
            <div className="column">
                <img className="logo" src={logo} alt="Yeah sure logo" />
                <div className="link">SHOP</div>
            </div>
            <div className="column" style={{ textAlign: "center" }}>
                <img className="burger" src={burger} alt="Menu" />
            </div>
            <div className="column" style={{ textAlign: "right" }}>
                <img className="icon" src={basket} alt="Basket" />
                <img className="icon" src={account} alt="Account" />
            </div>
        </div>
    );
}