import React from 'react';
import stars from "../../assets/img/stars.svg"

export default function Reviews(props) {
    return (
        <div className="reviews">
            <div className="title">
                REVIEWS
            </div>
            <img className="stars" src={stars} alt="stars" />
            <div className="actual_reviews">
                <div className="review">
                    <div className="text">
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
                    </div>
                    <div className="credit">
                        NAME / LOGO
                    </div>
                </div>
                <div className="divider" />
                <div className="review">
                    <div className="text">
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
                    </div>
                    <div className="credit">
                        NAME / LOGO
                    </div>
                </div>
                <div className="divider" />
                <div className="review">
                    <div className="text">
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
                    </div>
                    <div className="credit">
                        NAME / LOGO
                    </div>
                </div>
            </div>
        </div>
    );
}