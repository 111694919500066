import React, { useEffect, useState } from 'react';

export default function Banner(props) {
    const [text, setText] = useState(props.text)
    const [full, setFull] = useState([])

    useEffect(() => {
        let string = []
        for (let i = 0; i <= 500; i++) {
            string.push(text)
        }
        setFull(string)
    }, [text])

    useEffect(() => {
        setText(props.text)
    }, [props.text])

    return (
        <div className="banner">
            <div className="banner_wrap">
                {full.map((e, n) => {
                    return <div key={"banner_" + n} className="banner_section">
                        <div className="callout">{e}</div>
                        {n !== 501 ? <div className="dot">•</div> : null}
                    </div>
                })}
            </div>
        </div>
    );
}