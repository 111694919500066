import React from 'react';

import honey from "../../assets/img/honey_background.svg"

export default function Footer(props) {
    return (
        <div className="footer">
            <img className="honey_background" src={honey} alt="Honey background" />
            <img className="honey_background_2" src={honey} alt="Honey background" />

            <div className="section">
                <div className="links_section">
                    <div className="links_sub">
                        <div className="links_heading">
                            INFO
                        </div>
                        <div className="link">INFO</div>
                        <div className="link">INFO</div>
                        <div className="link">INFO</div>
                    </div>
                </div>

                <div className="links_section">
                    <div className="links_sub">
                        <div className="links_heading">
                            SOCIALS
                        </div>
                        <div className="link">SOCIALS</div>
                        <div className="link">SOCIALS</div>
                        <div className="link">SOCIALS</div>
                    </div>
                </div>

                <div className="links_section">
                    <div className="links_sub">
                        <div className="links_heading">
                            MORE INFO
                        </div>
                        <div className="link">MORE INFO</div>
                        <div className="link">MORE INFO</div>
                        <div className="link">MORE INFO</div>
                    </div>
                </div>
            </div>
        </div>
    );
}