import React from 'react';
import logos from "../../assets/img/logo_row.png"
import mints from "../../assets/img/mint_background.svg"

export default function LogoBlock(props) {
    return (
        <div className="logo_block">
            <img className="mints" src={mints} alt="Mint background" />
            <img className="logos" src={logos} alt="Logo row" />
        </div>
    );
}