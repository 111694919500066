import left from "../../assets/img/left_arrow.svg"
import right from "../../assets/img/right_arrow.svg"

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    // centerMode: true,
    centerPadding: "50px",
    prevArrow: <img src={left} alt="Left arrow" />,
    nextArrow: <img src={right} alt="Right arrow" />,
};

export default settings;