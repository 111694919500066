import React from 'react';
import Banner from '../banner/banner';
import LogoBlock from '../block/logo_block';
import Ocean from '../block/ocean';
import Footer from '../header/footer';
import Header from '../header/header';
import Intro from '../intro/intro';
import Mailing from '../mailing/mailing';
import Products from '../products/products';
import Reviews from '../reviews/reviews';
import logo from "../../assets/img/logo_white.svg"

export default function Main(props) {
    return (
        <div>
            <Banner text="COMING SOON" />
            <Header />
            <Intro />
            <Products />

            <Ocean />
            <Reviews />

            <LogoBlock />
            <Mailing />

            <div style={{
                position: "relative",
                textAlign: "center"
            }}>
                <img className="logo" src={logo} alt="Logo" style={{
                    width: "200px",
                    marginTop: "-50px",

                }} />
            </div>

            <Footer />
        </div>
    );
}