import honey from "../../assets/img/honey-bottle-svg.svg"
import original from "../../assets/img/original-bottle-svg.svg"
import mojito from "../../assets/img/mojito-bottle-svg.svg"

const bundle = {
    all_three: {
        img_1: honey,
        alt_1: "honey",
        img_2: original,
        alt_2: "original",
        img_3: mojito,
        alt_3: "mojito",
        title: "BUNDLE",
        description: "ONE OF EACH"
    },
    three_original: {
        img_1: original,
        alt_1: "original",
        img_2: original,
        alt_2: "original",
        img_3: original,
        alt_3: "Original",
        title: "ORIGINAL BUNDLE",
        description: "THREE ORIGINAL LEMONADES"
    }
}

export default bundle