import React, { useState } from 'react';
import btn from "../../assets/img/join_button.svg"
import validator from "email-validator"
import * as Icon from "react-bootstrap-icons"
import superagent from "superagent"

export default function Mailing(props) {
    const [email, setEmail] = useState("")

    function submit() {
        if (validator.validate(email)) {
            // superagent.get("http://localhost:3001/yeahsure/save")
            //     .send({ email })
            //     .then(res => {
            //         console.log(res)
            //         if (res.body.success) {
            //             document.getElementById("tick").style.display = "block"
            //         }
            //     })
            //     .catch(err => {
            //         console.log(err)
            //     })

            superagent.post("https://api.free.co.uk/yeahsure/save")
                .send({ email })
                .then(res => {
                    if (res.body.success) {
                        document.getElementById("tick").style.display = "block"
                    }
                })
                .catch(err => {
                    console.log(err)
                })

        } else {
            document.getElementById("x").style.display = "block"
            console.log("Email address not valid")
        }
    }

    return (
        <div className="mailing">
            <div className="content">
                <div className="title">
                    JOIN OUR MAILING LIST
                </div>
                <div className="description">
                    SIGN UP TO RECEIVE UPDATES ON OUR LIMITED EDITION DROPS,
                    SPECIAL MERCH &amp; MORE. NOTHING FANCY. NOTHING SPAMMY.
                </div>

                <div className="email_box">
                    <input onChange={e => {
                        setEmail(e.currentTarget.value)
                        document.getElementById("x").style.display = "none"
                        document.getElementById("tick").style.display = "none"
                    }} type="email" placeholder="ENTER EMAIL" />
                    <div className="invalid" id="x"><Icon.X /></div>
                    <div className="valid" id="tick"><Icon.Check /></div>
                    <img onClick={() => submit()} className="btn" src={btn} alt="Joing button" />
                </div>
            </div>
        </div>
    );
}