import React from 'react';
import btn from "../../assets/img/button.svg"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import settings from "./settings"
import single from '../products/single';
import ocean from "../../assets/img/ocean.svg"

export default function Ocean(props) {
    return (
        <div className="ocean" >
            <div className="prepare_block">
                <div className="text">
                    PREPARE FOR MAXIMUM<br />REFRESHMENT
                </div>
                <div className="button">
                    <img className="buy_now" src={btn} alt="Button" />
                    <div className="button_text">BUY NOW</div>
                </div>
            </div>
            <div className="slideshow_block">
                <Slider className="slideshow" {...settings}>
                    {Object.entries(single).map((e, n) => {
                        return <div className="product">
                            <img src={e[1].img} alt={e[1].title} />
                        </div>
                    })}
                </Slider>
            </div>
            <img className="logo_ocean" src={ocean} alt="ocean" />
        </div>
    );
}